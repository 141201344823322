import { ApiResponse, Pagination, Feedback, FeedbackPageModel } from '@models';
import { createActionGroup, emptyProps, props } from '@ngrx/store';

const feedbackActions = createActionGroup({
  source: 'Feedback page',
  events: {
    'fetch Feedback': emptyProps(),
    'fetch feedback success': props<{ data: ApiResponse<Feedback[]> }>(),
    'archive feedback': props<{ id: Feedback['id'] }>(),
    'update filter': props<{ data: FeedbackPageModel.filter }>(),
    'update Pagination': props<{ data: Pagination }>(),
    'export to csv': emptyProps(),
  },
});
export default feedbackActions;
