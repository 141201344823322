import { createFeatureSelector, createSelector } from '@ngrx/store';
import { FeedbackState } from './feedback.state';

export const FEEDBACK_STATE_NAME = 'feedback';
const selectFeedbackState =
  createFeatureSelector<FeedbackState>(FEEDBACK_STATE_NAME);
export const selectFeedbackCount = createSelector(
  selectFeedbackState,
  state => state.total
);
const selectFeedbacks = createSelector(selectFeedbackState, ({ data }) => {
  return data;
});

const selectFilter = createSelector(
  selectFeedbackState,
  ({ filter }) => filter
);
const selectPagination = createSelector(
  selectFeedbackState,
  ({ pagination }) => pagination
);

const selectPaginationAndFilter = createSelector(
  selectFeedbackState,
  ({ pagination, filter }) => ({ filter, pagination })
);
const selectTotal = createSelector(selectFeedbackState, ({ total }) => total);
export default {
  selectFilter,
  selectFeedbacks,
  selectTotal,
  selectPagination,

  selectPaginationAndFilter,
};
